var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-12"},[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('div',{staticClass:"title-container"},[_c('div',{staticClass:"title-block"},[_c('span',{staticClass:"page-title",attrs:{"id":"title"}},[_vm._v("Totem de Pagamento")]),_c('v-breadcrumbs',{staticClass:"app-breadcrumb",attrs:{"divider":"›","items":[
            {
                text: 'Homepage',
                disabled: false,
                to: '/home',
                exact: true,
            },
            {
                text: 'Totem de pagamento',
                disabled: true,
                exact: true,
            } ]}})],1)])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"app-table hover-table",attrs:{"headers":_vm.dataList.headers,"items":_vm.dataList.data,"server-items-length":_vm.dataList.count,"disable-sort":true,"loading":_vm.dataList.loading,"no-data-text":"Nenhuma upbox foi encontrada","loading-text":"Carregando dados...","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.identificador)+" ")]}},{key:"item.version",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.versao)+" ")]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:item.habilitado ? 'green--text' : 'error--text'},[_c('strong',[_vm._v(_vm._s(item.habilitado ? "Habilitado" : "Desabilitado"))])])]}},{key:"item.lastUpdated",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("defaultDate")(item.dataUltimaComunicacao,"DD/MM/YYYY HH:mm:ss"))+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-row',_vm._g({attrs:{"align":"center"}},on),[_c('v-icon',{attrs:{"size":"large"},on:{"click":function($event){return _vm.restartTotem(item.id)}}},[_vm._v(" mdi-refresh ")])],1)]}}],null,true)},[_c('span',[_vm._v("Reiniciar totem")])])]}}],null,true)}),_c('v-divider')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }