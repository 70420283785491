import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";

import MachineList from "@/views/machine/MachineList.vue";
import MachineDetails from "@/views/machine/MachineDetails.vue";

import UpboxList from "@/views/upbox/UpboxList.vue";
import UpboxDetails from "@/views/upbox/UpboxDetails.vue";

import PaymentTotemList from "@/views/payment-totem/PaymentTotemList.vue";
import PaymentTotemDetails from "@/views/payment-totem/PaymentTotemDetails.vue";

import SaleHistoryList from "@/views/sale-history/SaleHistoryList.vue";

const guard = function (to: any, from: any, next: any) {
  const token = localStorage.getItem("token");

  const publicPath = to.matched.find(
    (path) => path.name === "Public" || path.name === "Home"
  );
  // if (!token && to.path !== "/login" && !publicPath) {
  //   next({
  //     path: "/login",
  //   });
  // } else {

  //   if(to.name == "Home") {
  //     next();
  //     return;
  //   }

  next();
  // }
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/layouts/Public.vue"),
    children: [
      // {
      //   path: "/login",
      //   name: "login",
      //   component: Login,
      // },
      {
        path: "/",
        // redirect: "/login",
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/Private.vue"),
    beforeEnter: guard,
    children: [
      {
        path: "/home",
        name: "Home",
        component: Home,
        beforeEnter: guard,
      },
      {
        path: "/maquinas",
        name: "MachineList",
        component: MachineList,
        beforeEnter: guard,
      },
      {
        path: "/maquinas/:id",
        name: "MachineDetails",
        component: MachineDetails,
        beforeEnter: guard,
      },
      {
        path: "/upboxes",
        name: "UpboxList",
        component: UpboxList,
        beforeEnter: guard,
      },
      {
        path: "/upboxes/:id",
        name: "UpboxDetails",
        component: UpboxDetails,
        beforeEnter: guard,
      },
      {
        path: "/totens",
        name: "PaymentTotemList",
        component: PaymentTotemList,
        beforeEnter: guard,
      },
      {
        path: "/totens/:id",
        name: "PaymentTotemDetails",
        component: PaymentTotemDetails,
        beforeEnter: guard,
      },
      {
        path: "/historico-vendas",
        name: "SaleHistoryList",
        component: SaleHistoryList,
        beforeEnter: guard,
      },
    ],
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
