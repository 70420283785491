var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"mt-10"},[_c('span',{staticClass:"home-title-page"},[_vm._v("Visão Geral")])])])],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"home-global-card"},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"home-card-details",attrs:{"loading":_vm.dataset.loading}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',{staticClass:"text-h6 mr-4 home-card-details-title"},[_vm._v(" Filtros ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"disabled":_vm.dataset.loading,"dense":"","items":_vm.dataset.periodTypes,"label":"Período","item-value":"type","item-text":"label","outlined":"","rules":[_vm.$rules.required],"required":"","hide-details":"auto"},on:{"change":_vm.normalizeRangeByType},model:{value:(_vm.dataset.filterSelected),callback:function ($$v) {_vm.$set(_vm.dataset, "filterSelected", $$v)},expression:"dataset.filterSelected"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.$moment(_vm.dataset.rangeDate.startDate).format('DD/MM/YYYY'),"disabled":_vm.dataset.filterSelected != 9,"readonly":"","dense":"","label":"Data início","required":"","outlined":"","hide-details":""}},'v-text-field',attrs,false),_vm.dataset.filterSelected == 9 ? on : null))]}}]),model:{value:(_vm.dataset.datePickerStartDialog),callback:function ($$v) {_vm.$set(_vm.dataset, "datePickerStartDialog", $$v)},expression:"dataset.datePickerStartDialog"}},[_c('v-date-picker',{attrs:{"locale":"pt-br","scrollable":""},model:{value:(_vm.dataset.rangeDate.startDate),callback:function ($$v) {_vm.$set(_vm.dataset.rangeDate, "startDate", $$v)},expression:"dataset.rangeDate.startDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dataset.datePickerStartDialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.dataset.rangeDate.startDate); _vm.dataset.datePickerStartDialog = false}}},[_vm._v(" Salvar ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-dialog',{ref:"dialog",attrs:{"persistent":"","width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.$moment(_vm.dataset.rangeDate.endDate).format('DD/MM/YYYY'),"disabled":_vm.dataset.filterSelected != 9,"readonly":"","dense":"","label":"Data fim","required":"","outlined":"","hide-details":""}},'v-text-field',attrs,false),_vm.dataset.filterSelected == 9 ? on : null))]}}]),model:{value:(_vm.dataset.datePickerEndDialog),callback:function ($$v) {_vm.$set(_vm.dataset, "datePickerEndDialog", $$v)},expression:"dataset.datePickerEndDialog"}},[_c('v-date-picker',{attrs:{"locale":"pt-br","scrollable":""},model:{value:(_vm.dataset.rangeDate.endDate),callback:function ($$v) {_vm.$set(_vm.dataset.rangeDate, "endDate", $$v)},expression:"dataset.rangeDate.endDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dataset.datePickerEndDialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dialog.save(_vm.dataset.rangeDate.endDate); _vm.dataset.datePickerEndDialog = false}}},[_vm._v(" Salvar ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"button-group"},[_c('v-btn',{attrs:{"loading":_vm.dataset.loading,"type":"button","elevation":"0","color":"primary"},on:{"click":function($event){return _vm.loadData()}}},[_vm._v("Filtrar")])],1)])],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{staticClass:"home-card-details"},[_c('v-card-title',[_c('span',{staticClass:"home-card-details-title"},[_vm._v(" Vendas "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Este contador exibe o número total de vendas com base no filtro selecionado.")])])],1)]),_c('v-card-text',[_c('span',{staticClass:"text-h5 font-weight-bold"},[_vm._v(_vm._s(_vm.dataset.totalSale.qtdVendas))])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{staticClass:"home-card-details"},[_c('v-card-title',[_c('span',{staticClass:"home-card-details-title"},[_vm._v(" Ciclos "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Este contador exibe o número total de ciclos com base no filtro selecionado.")])])],1)]),_c('v-card-text',[_c('span',{staticClass:"text-h5 font-weight-bold"},[_vm._v(_vm._s(_vm.dataset.totalSale.qtdCiclos))])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{staticClass:"home-card-details"},[_c('v-card-title',[_c('span',{staticClass:"home-card-details-title"},[_vm._v(" Valor total "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Este é o valor total das vendas com base no filtro selecionado.")])])],1)]),_c('v-card-text',[_c('span',{staticClass:"text-h5 font-weight-bold"},[_vm._v("R$ "+_vm._s(_vm._f("currency")(_vm.dataset.totalSale.valorTotal)))])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-card',{staticClass:"home-card-details"},[_c('v-card-title',[_c('span',{staticClass:"home-card-details-title"},[_vm._v(" Ticked Médio "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Este é o ticket médio das vendas com base no filtro selecionado.")])])],1)]),_c('v-card-text',[_c('span',{staticClass:"text-h5 font-weight-bold"},[_vm._v("R$ "+_vm._s(_vm._f("currency")(_vm.dataset.totalSale.ticketMedio)))])])],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"home-card-details"},[_c('v-card-title',[_c('span',{staticClass:"home-card-details-title"},[_vm._v(" Vendas por horário "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Este gráfico exibe todas as vendas separadas por horário e com base no filtro selecionado.")])])],1)]),_c('v-card-text',[_c('apexchart',{attrs:{"height":"300","type":"bar","options":_vm.dataset.timeResumeChartOptions.chartOptions,"series":_vm.dataset.timeResumeChartOptions.series}})],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"home-card-details"},[_c('v-card-title',[_c('span',{staticClass:"home-card-details-title"},[_vm._v(" Quantidade de Ciclos "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Este gráfico exibe os ciclos separados por dia e com base no filtro selecionado.")])])],1)]),_c('v-card-text',[_c('apexchart',{attrs:{"height":"300","type":"area","options":_vm.dataset.cycleResumeChartOptions.chartOptions,"series":_vm.dataset.cycleResumeChartOptions.series}})],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"home-card-details"},[_c('v-card-title',[_c('span',{staticClass:"home-card-details-title"},[_vm._v(" Vendas por bandeira "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Este gráfico exibe todas as vendas separadas por bandeira e com base no filtro selecionado.")])])],1)]),_c('v-card-text',[_c('apexchart',{attrs:{"height":"300","type":"donut","options":_vm.dataset.saleBrandResumeChartOptions.chartOptions,"series":_vm.dataset.saleBrandResumeChartOptions.series}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{staticClass:"home-card-details"},[_c('v-card-title',[_c('span',{staticClass:"home-card-details-title"},[_vm._v(" Vendas por tipo de pagamento "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Este gráfico exibe todas as vendas separadas por tipo e com base no filtro selecionado.")])])],1)]),_c('v-card-text',[_c('apexchart',{attrs:{"height":"300","type":"donut","options":_vm.dataset.paymentTypeChartOptions.chartOptions,"series":_vm.dataset.paymentTypeChartOptions.series}})],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }