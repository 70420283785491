<template>
    <v-container class="py-12">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›"
                            :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined
                            to="/upboxes">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Detalhes</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field disabled dense label="Identificador"
                                                v-model="dataset.data.identificador" required :rules="[$rules.required]"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select dense :items="dataset.totemStatus" label="Status"
                                                v-model="dataset.data.habilitado" item-value="value" item-text="name"
                                                outlined :rules="[$rules.required]" required hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field disabled dense type="number" label="Versão"
                                                v-model="dataset.data.versao" required outlined hide-details="auto"
                                                :rules="[$rules.required]">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field disabled dense label="Código Stone"
                                                v-model="dataset.data.stoneCode" required outlined hide-details="auto"
                                                :rules="[$rules.required]">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-card :disabled="dataset.loading" class="card-form-outlined">
                        <v-card-title class="card-group-title">Ações</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12">
                                    <v-btn outlined type="button" elevation="0" color="primary" dark
                                        @click="restartTotem">Reiniciar totem</v-btn>
                                </v-col>
                                <!-- <v-col cols="12" sm="6">
                                            <v-select dense :items="dataset.machineTypes" label="Tipo de máquina"
                                                v-model="dataset.data.tipo" item-value="id" item-text="name" outlined
                                                :rules="[$rules.required]" required hide-details="auto">
                                            </v-select>
                                        </v-col> -->
                            </v-row>

                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>

    </v-container>
</template>

<script>
import totemStatus from "@/common/data/totem-status.data"

import axios from "axios"

export default {
    name: "PaymentTotemDetails",

    data: () => ({
        page: {
            title: "",
        },
        dataset: {
            data: {},
            breadcrumbItems: [],
            totemStatus: totemStatus,
            loading: false,
        },
        dataForm: {
            validForm: true,
        },
    }),

    created() {
        this.loadData()
    },

    methods: {
        goBack() {
            this.$router.replace("/totens");
        },

        async loadData() {
            this.setPage();
            this.listBreadCrumbItems();

            await this.load();
        },

        setPage() {
            this.page.title = `Detalhes do totem`;
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Listagem de totens",
                disabled: false,
                to: "/totens",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: `Detalhes do totem`,
                disabled: true,
                exact: true,
            });
        },

        async load() {
            this.dataset.loading = true

            try {
                const result = await axios.get(`/api/totem-pagamento/${this.$route.params.id}`);

                this.dataset.data = result.data

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.loading = false;
            }
        },

        async restartTotem() {

            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja reiniciar o totem?',
                title: 'Totem',
                actions: {
                    false: 'Cancelar',
                    true: 'Enviar'
                }
            })

            if (!proceedAction) return;

            this.dataForm.addLoading = true;

            try {
                await axios.post(`/api/totem-pagamento/${this.$route.params.id}/restart`, {});

                this.$dialog.notify.info("Comando para reiniciar o totem foi enviado com sucesso!", { position: "top-right", timeout: 2000 });

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataForm.addLoading = false;
            }
        },
    },
};
</script>

<style lang="scss">
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}

.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    // font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
}
</style>