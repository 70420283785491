<template>
    <v-container>

        <v-row>
            <v-col>
                <div class="mt-10"><span class="home-title-page">Visão Geral</span></div>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card class="home-global-card">
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <v-card :loading="dataset.loading" class="home-card-details">
                                    <v-card-text>
                                        <v-row>
                                            <v-col>
                                                <span class="text-h6 mr-4 home-card-details-title">
                                                    Filtros
                                                </span>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col cols="12" md="4">
                                                <v-select :disabled="dataset.loading" v-on:change="normalizeRangeByType"
                                                    dense :items="dataset.periodTypes" label="Período"
                                                    v-model="dataset.filterSelected" item-value="type" item-text="label"
                                                    outlined :rules="[$rules.required]" required hide-details="auto">
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" md="4">
                                                <v-dialog ref="dialog" v-model="dataset.datePickerStartDialog"
                                                    persistent width="290px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            :value="$moment(dataset.rangeDate.startDate).format('DD/MM/YYYY')"
                                                            :disabled="dataset.filterSelected != 9" readonly dense
                                                            label="Data início" required v-bind="attrs"
                                                            v-on="dataset.filterSelected == 9 ? on : null" outlined
                                                            hide-details>
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker locale="pt-br" v-model="dataset.rangeDate.startDate"
                                                        scrollable>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text color="primary"
                                                            @click="dataset.datePickerStartDialog = false">
                                                            Cancelar
                                                        </v-btn>
                                                        <v-btn text color="primary"
                                                            @click="$refs.dialog.save(dataset.rangeDate.startDate); dataset.datePickerStartDialog = false">
                                                            Salvar
                                                        </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>

                                            </v-col>

                                            <v-col cols="12" md="4">
                                                <v-dialog ref="dialog" v-model="dataset.datePickerEndDialog" persistent
                                                    width="290px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            :value="$moment(dataset.rangeDate.endDate).format('DD/MM/YYYY')"
                                                            :disabled="dataset.filterSelected != 9" readonly dense
                                                            label="Data fim" required v-bind="attrs"
                                                            v-on="dataset.filterSelected == 9 ? on : null" outlined
                                                            hide-details>
                                                        </v-text-field>
                                                    </template>
                                                    <v-date-picker locale="pt-br" v-model="dataset.rangeDate.endDate"
                                                        scrollable>
                                                        <v-spacer></v-spacer>
                                                        <v-btn text color="primary"
                                                            @click="dataset.datePickerEndDialog = false">
                                                            Cancelar
                                                        </v-btn>
                                                        <v-btn text color="primary"
                                                            @click="$refs.dialog.save(dataset.rangeDate.endDate); dataset.datePickerEndDialog = false">
                                                            Salvar
                                                        </v-btn>
                                                    </v-date-picker>
                                                </v-dialog>
                                            </v-col>
                                        </v-row>

                                        <v-row>
                                            <v-col>
                                                <div class="button-group">
                                                    <v-btn :loading="dataset.loading" type="button" elevation="0"
                                                        color="primary" @click="loadData()">Filtrar</v-btn>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="3">
                                <v-card class="home-card-details">
                                    <v-card-title>
                                        <span class="home-card-details-title">
                                            Vendas
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                                                </template>
                                                <span>Este contador exibe o número total de vendas com base no filtro selecionado.</span>
                                            </v-tooltip>
                                        </span>
                                    </v-card-title>
                                    <v-card-text>
                                        <span class="text-h5 font-weight-bold">{{ dataset.totalSale.qtdVendas }}</span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card class="home-card-details">
                                    <v-card-title>
                                        <span class="home-card-details-title">
                                            Ciclos
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                                                </template>
                                                <span>Este contador exibe o número total de ciclos com base no filtro selecionado.</span>
                                            </v-tooltip>
                                        </span>
                                    </v-card-title>
                                    <v-card-text>
                                        <span class="text-h5 font-weight-bold">{{ dataset.totalSale.qtdCiclos }}</span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card class="home-card-details">
                                    <v-card-title>
                                        <span class="home-card-details-title">
                                            Valor total
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                                                </template>
                                                <span>Este é o valor total das vendas com base no filtro selecionado.</span>
                                            </v-tooltip>
                                        </span>
                                    </v-card-title>
                                    <v-card-text>
                                        <span class="text-h5 font-weight-bold">R$ {{ dataset.totalSale.valorTotal |
                                    currency
                                            }}</span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-card class="home-card-details">
                                    <v-card-title>
                                        <span class="home-card-details-title">
                                            Ticked Médio
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                                                </template>
                                                <span>Este é o ticket médio das vendas com base no filtro selecionado.</span>
                                            </v-tooltip>
                                        </span>
                                    </v-card-title>
                                    <v-card-text>
                                        <span class="text-h5 font-weight-bold">R$ {{ dataset.totalSale.ticketMedio |
                                    currency
                                            }}</span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-row>
                                    <v-col>
                                        <v-card class="home-card-details">
                                            <v-card-title>
                                                <span class="home-card-details-title">
                                                    Vendas por horário
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                                                        </template>
                                                        <span>Este gráfico exibe todas as vendas separadas por horário e com base no filtro selecionado.</span>
                                                    </v-tooltip>
                                                </span>
                                            </v-card-title>
                                            <v-card-text>
                                                <apexchart height="300" type="bar"
                                                    :options="dataset.timeResumeChartOptions.chartOptions"
                                                    :series="dataset.timeResumeChartOptions.series"></apexchart>
                                                <!-- <highcharts class="hc" :options="dataset.timeResumeChartOptions"
                                                    ref="chart">
                                                </highcharts> -->
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-card class="home-card-details">
                                            <v-card-title>
                                                <span class="home-card-details-title">
                                                    Quantidade de Ciclos
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                                                        </template>
                                                        <span>Este gráfico exibe os ciclos separados por dia e com base no filtro selecionado.</span>
                                                    </v-tooltip>
                                                </span>
                                            </v-card-title>
                                            <v-card-text>
                                                <apexchart height="300" type="area"
                                                    :options="dataset.cycleResumeChartOptions.chartOptions"
                                                    :series="dataset.cycleResumeChartOptions.series"></apexchart>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <!-- <v-row>
                                    <v-col>
                                        <v-card class="home-card-details">
                                            <v-card-title>
                                                <span class="home-card-details-title">
                                                    Resumo
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                                                        </template>
                                                        <span>informação sobre vendas nesta semana</span>
                                                    </v-tooltip>
                                                </span>
                                            </v-card-title>
                                        </v-card>
                                    </v-col>
                                </v-row> -->
                            </v-col>
                            <!-- <v-col sm="4">
                                <v-card class="home-card-details">
                                    <v-card-title>
                                        <span class="home-card-details-title">
                                            Histórico de vendas
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                                                </template>
                                                <span>informação sobre vendas nesta semana</span>
                                            </v-tooltip>
                                        </span>
                                    </v-card-title>
                                </v-card>
                            </v-col> -->
                        </v-row>
                        <v-row>
                            <v-col sm="12" md="12">
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-card class="home-card-details">
                                            <v-card-title>
                                                <span class="home-card-details-title">
                                                    Vendas por bandeira
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                                                        </template>
                                                        <span>Este gráfico exibe todas as vendas separadas por bandeira e com base no filtro selecionado.</span>
                                                    </v-tooltip>
                                                </span>
                                            </v-card-title>
                                            <v-card-text>
                                                <apexchart height="300" type="donut"
                                                    :options="dataset.saleBrandResumeChartOptions.chartOptions"
                                                    :series="dataset.saleBrandResumeChartOptions.series"></apexchart>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-card class="home-card-details">
                                            <v-card-title>
                                                <span class="home-card-details-title">
                                                    Vendas por tipo de pagamento
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                                                        </template>
                                                        <span>Este gráfico exibe todas as vendas separadas por tipo e com base no filtro selecionado.</span>
                                                    </v-tooltip>
                                                </span>
                                            </v-card-title>
                                            <v-card-text>
                                                <apexchart height="300" type="donut"
                                                    :options="dataset.paymentTypeChartOptions.chartOptions"
                                                    :series="dataset.paymentTypeChartOptions.series"></apexchart>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <!-- <v-row>
                                    <v-col>
                                        <v-card class="home-card-details">
                                            <v-card-title>
                                                <span class="home-card-details-title">
                                                    Resumo
                                                    <v-tooltip bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                                                        </template>
                                                        <span>informação sobre vendas nesta semana</span>
                                                    </v-tooltip>
                                                </span>
                                            </v-card-title>
                                        </v-card>
                                    </v-col>
                                </v-row> -->
                            </v-col>
                            <!-- <v-col sm="4">
                                <v-card class="home-card-details">
                                    <v-card-title>
                                        <span class="home-card-details-title">
                                            Histórico de vendas
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                                                </template>
                                                <span>informação sobre vendas nesta semana</span>
                                            </v-tooltip>
                                        </span>
                                    </v-card-title>
                                </v-card>
                            </v-col> -->
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import 'moment/locale/pt-br';

import axios from "axios"
import { eventHub } from '@/main';

export default {
    data: () => ({
        dataset: {
            operation: null,
            loading: false,
            totalSale: {
                qtdCiclos: 0,
                qtdVendas: 0,
                ticketMedio: 0.00,
                valorTotal: 0.00,
            },
            createdEvent: false,
            datePickerStartDialog: false,
            datePickerEndDialog: false,
            periodTypes: [
                {
                    label: 'Hoje',
                    type: 1
                },
                {
                    label: 'Ontem',
                    type: 2
                },
                {
                    label: '7 dias',
                    type: 3
                },
                {
                    label: '15 dias',
                    type: 4
                },
                {
                    label: '30 dias',
                    type: 5
                },
                {
                    label: 'Esse mês',
                    type: 6
                },
                {
                    label: 'Mês passado',
                    type: 7
                },
                {
                    label: 'Últimos 3 meses',
                    type: 8
                },
                {
                    label: 'Personalizado',
                    type: 9
                },
            ],
            filterSelected: 3,
            rangeDate: { startDate: null, endDate: null },
            timeResume: [],
            cycleResume: [],
            saleBrand: [],
            timeResumeChartOptions: {
                chartOptions: {
                    chart: {
                        height: 300,
                        stacked: true,
                        toolbar: {
                            show: false
                        },
                    },
                    xaxis: {
                        categories: ['123', '123545']
                    }
                },
                series: [{
                    name: 'Vendas',
                    data: []
                }]
            },
            cycleResumeChartOptions: {
                chartOptions: {
                    chart: {
                        type: 'area',
                        height: 300,
                        stacked: false,
                        toolbar: {
                            show: false
                        },
                    },
                    xaxis: {
                        categories: []
                    }
                },
                series: [{
                    name: 'Ciclos',
                    data: []
                }]
            },
            saleBrandResumeChartOptions: {
                chartOptions: {
                    chart: {
                        type: 'donut',
                        height: 300,
                        responsive: false
                    },
                    colors: ['#55baaa', '#1e48ab', '#eba33e'],
                    labels: [],
                    tooltip: {
                        fillSeriesColor: true,
                        y: {
                            formatter: function (value, opt) {
                                return 'R$ ' + parseFloat(value.toString()).toFixed(2);
                            },
                        }
                    }
                },
                series: [0, 0, 0, 0]
            },
            paymentTypeChartOptions: {
                chartOptions: {
                    chart: {
                        type: 'donut',
                        height: 300,
                        responsive: false
                    },
                    colors: ['#55baaa', '#1e48ab', '#eba33e'],
                    labels: [],
                    tooltip: {
                        fillSeriesColor: true,
                        y: {
                            formatter: function (value, opt) {
                                return 'R$ ' + parseFloat(value.toString()).toFixed(2);
                            },
                        }
                    }
                },
                series: [0, 0, 0, 0]
            }
        }
    }),

    mounted: function () {
        this.user = this.$root.user;

        this.getSelectedOperation();

        if (this.dataset.operation && !this.dataset.createdEvent) {
        //     this.$dialog.notify.error('Nenhuma operação selecionada!', { position: 'top-right', timeout: 5000 });
            this.loadData();
        //     return;
        }


        eventHub.$on('CHANGE-OPERATION', (value) => {
            this.getSelectedOperation();
            this.loadData()
        })
    },

    destroyed() {
        eventHub.$off('CHANGE-OPERATION')
    },

    created() {
        this.createdEvent = true
    },

    methods: {

        async loadData() {

            this.normalizeRangeByType();

            console.log('range filter ', this.dataset.rangeDate)

            this.dataset.loading = true;

            try {
                await this.loadTotalSales();
                await this.loadTimeResume();
                await this.loadSaleBrand();
                await this.loadPaymentTypes();
                await this.loadCycleResume();
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.loading = false;
            }

        },

        normalizedDateRange(dates) {
            return dates.map((x) => this.$moment(x).format('DD/MM/YYYY')).join(" - ")
        },

        normalizeRangeByType() {
            switch (this.dataset.filterSelected) {
                case 1:
                    this.dataset.rangeDate.startDate = this.$moment().format('YYYY-MM-DD');
                    this.dataset.rangeDate.endDate = this.$moment().format('YYYY-MM-DD');
                    break;
                case 2:
                    this.dataset.rangeDate.startDate = this.$moment().subtract(1, 'd').format('YYYY-MM-DD');
                    this.dataset.rangeDate.endDate = this.$moment().subtract(1, 'd').format('YYYY-MM-DD');
                    break;
                case 3:
                    this.dataset.rangeDate.startDate = this.$moment().subtract(7, 'd').startOf('day').format('YYYY-MM-DD');
                    this.dataset.rangeDate.endDate = this.$moment().format('YYYY-MM-DD');
                    break;
                case 4:
                    this.dataset.rangeDate.startDate = this.$moment().subtract(15, 'd').format('YYYY-MM-DD');
                    this.dataset.rangeDate.endDate = this.$moment().format('YYYY-MM-DD');
                    break;
                case 5:
                    this.dataset.rangeDate.startDate = this.$moment().subtract(30, 'd').format('YYYY-MM-DD');
                    this.dataset.rangeDate.endDate = this.$moment().format('YYYY-MM-DD');
                    break;
                case 6:
                    this.dataset.rangeDate.startDate = this.$moment().startOf('month').format('YYYY-MM-DD');
                    this.dataset.rangeDate.endDate = this.$moment().endOf('month').format('YYYY-MM-DD');
                    break;
                case 7:
                    this.dataset.rangeDate.startDate = this.$moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
                    this.dataset.rangeDate.endDate = this.$moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
                    break;
                case 8:
                    this.dataset.rangeDate.startDate = this.$moment().subtract(3, 'month').format('YYYY-MM-DD');
                    this.dataset.rangeDate.endDate = this.$moment(new Date()).format('YYYY-MM-DD');
                    break;

                default:
                    break;
            }
        },

        changeFilter(type) {
            this.dataset.filterSelected = type;

            if (type != 5) this.loadData();
        },

        async loadSaleBrand() {
            const result = await axios.get(`/api/venda/resumo/tipo-bandeira?dataInicial=${this.dataset.rangeDate.startDate}&operacaoId=${this.dataset.operation}&dataFinal=${this.dataset.rangeDate.endDate}`, { params: {} });

            this.dataset.saleBrand = result.data;

            let saleBrandResumeData = this.dataset.saleBrand.map((x) => x.valor)
            let saleBrandResumeCategories = this.dataset.saleBrand.map((x) => x.descricao ? x.descricao : 'OUTROS')

            this.resolveSaleBrandChart(saleBrandResumeCategories, saleBrandResumeData)
        },

        async loadTimeResume() {
            const result = await axios.get(`/api/venda/resumo/horario?dataInicial=${this.dataset.rangeDate.startDate}&operacaoId=${this.dataset.operation}&dataFinal=${this.dataset.rangeDate.endDate}`, { params: {} });

            this.dataset.timeResume = result.data;

            let timeResumeData = this.dataset.timeResume.map((x) => x.quantidade)
            let timeResumeCategories = this.dataset.timeResume.map((x) => `${x.hora}h`)

            this.resolveTimeResumeChart(timeResumeCategories, timeResumeData)
        },

        async loadCycleResume() {
            const result = await axios.get(`/api/maquina/resumo/ciclos-por-maquina-dia?dataInicial=${this.dataset.rangeDate.startDate}&operacaoId=${this.dataset.operation}&dataFinal=${this.dataset.rangeDate.endDate}`, { params: {} });

            this.dataset.cycleResume = result.data;

            let cycleResumeData = this.dataset.cycleResume
            
            let cycleResumeCategories = []

            for(let item of this.dataset.cycleResume) {
                let date = this.$moment(item.data).format("DD/MM/YYYY")

                let isExistCategory = cycleResumeCategories.findIndex((x) => x == date);

                if(isExistCategory != -1) continue;

                cycleResumeCategories.push(date);
            }

            this.resolveCycleResumeChart(cycleResumeCategories, cycleResumeData)
        },

        async loadTotalSales() {
            const result = await axios.get(`/api/venda/resumo/totalizadores?dataInicial=${this.dataset.rangeDate.startDate}&operacaoId=${this.dataset.operation}&dataFinal=${this.dataset.rangeDate.endDate}`, { params: {} });

            this.dataset.totalSale = result.data;
            // this.resolveChartLastUsers(result.data.content)
        },

        async loadPaymentTypes() {

            const result = await axios.get(`/api/venda/resumo/tipo-pagamento?dataInicial=${this.dataset.rangeDate.startDate}&operacaoId=${this.dataset.operation}&dataFinal=${this.dataset.rangeDate.endDate}`, { params: {} });

            this.dataset.totalPaymentTypes = result.data;

            let paymentTypeResumeData = this.dataset.totalPaymentTypes.map((x) => x.valor)
            let paymentTypeResumeCategories = this.dataset.totalPaymentTypes.map((x) => x.descricao)

            this.resolvePaymentTypeChart(paymentTypeResumeCategories, paymentTypeResumeData)
        },

        resolveTimeResumeChart(categories, data) {
            this.dataset.timeResumeChartOptions.chartOptions = { ...this.dataset.timeResumeChartOptions.chartOptions, xaxis: { categories } };
            this.dataset.timeResumeChartOptions.series = [{ data }];
        },

        resolveCycleResumeChart(categories, data) {
            this.dataset.cycleResumeChartOptions.chartOptions = { ...this.dataset.cycleResumeChartOptions.chartOptions, labels: [], xaxis: { categories } };

            let newData = []

            console.log('categories ', categories)

            for(let item of data) {
                let date = this.$moment(item.data).format('DD/MM/YYYY');

                let indexData = newData.findIndex((x) => x.descricao == item.descricao)
                let findCategory = categories.findIndex((x) => x == date);
                
                // let categoriesData = categories.map(() => 0);;
                
                if(indexData == -1) {
                    let items = categories.map(() => 0);

                    items[findCategory] = item.qtdCiclos;
                    
                    newData.push({descricao: item.descricao, items });
                    continue;
                }

                newData[indexData].items[findCategory] = item.qtdCiclos;
            }

            this.dataset.cycleResumeChartOptions.labels = newData.map((x) => x.descricao)

            this.dataset.cycleResumeChartOptions.series = newData.map((x) => {return {name: x.descricao, data: x.items}});
        },

        resolveSaleBrandChart(categories, data) {
            this.dataset.saleBrandResumeChartOptions.chartOptions = { ...this.dataset.saleBrandResumeChartOptions, labels: categories };
            this.dataset.saleBrandResumeChartOptions.series = [];

            for (let item of data) {
                this.dataset.saleBrandResumeChartOptions.series.push(item)
            }
        },

        resolvePaymentTypeChart(categories, data) {
            this.dataset.paymentTypeChartOptions.chartOptions = { ...this.dataset.paymentTypeChartOptions, labels: categories };
            this.dataset.paymentTypeChartOptions.series = [];

            for (let item of data) {
                this.dataset.paymentTypeChartOptions.series.push(item)
            }
        },

        getSelectedOperation() {
            this.dataset.operation = localStorage.getItem('operation');
        },

    }
};
</script>

<style lang="scss">
.home-title-page {
    color: var(--v-mediumBlack-base);
    font-family: "Montserrat";
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
}

.container-chart {
    max-width: 400px;
}

.home-card-details {
    background-color: var(--v-white-base) !important;
}

.home-card-details-title {
    text-transform: none !important;
    font-family: 'Poppins', sans-serif;
    color: var(--v-mediumBlack-base);
    font-size: 16px;
    font-weight: 500;
}

.home-global-card {
    background-color: var(--v-offWhite-base) !important;
}
</style>
