import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import { pt } from 'vuetify/lib/locale'
import { en } from 'vuetify/lib/locale'

Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    current: 'ptbr'
  },
  // lang: {
  //   locales: {
  //     pt,
  //     en
  //   },
  //   current: 'pt'
  // },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#3BA5DB',
        secondary: '#FFFFFF',
        dark: '#111111',
        gray: '#F7F7F7',
        lightGray: '#EFEFEF',
        offWhite: '#F2F2F2',
        black: '#101828',
        mediumBlack: '#0F0F0F',
        mediumGray: '#959595',
        darkGray: '#9E9E9E',
        important: '#FB3A04',
        lightImportant: '#FEF2F2',
        white: '#FFFFFF',
        accent: '#4CAF50',
        error: '#f59a9a'
      }
    }
  }
})
