<template>
    <v-container class="py-12">
        <v-row class="d-none d-sm-flex">
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="update"
                            :loading="dataset.loading">Salvar</v-btn>
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined
                            to="/maquinas">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row class="d-sm-none d-lg-none d-md-flex">
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                    </div>
                </div>
                <div>
                    <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                </div>
            </v-col>
        </v-row>

        <v-row class="d-sm-none d-lg-none d-md-flex">
            <v-col>
                <div class="button-group">
                    <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="update"
                        :loading="dataset.loading">Salvar</v-btn>
                    <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined to="/maquinas">Voltar</v-btn>
                </div>
            </v-col>
        </v-row>

        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Detalhes</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense label="Nome" v-model="dataset.data.nome" required
                                                :rules="[$rules.required, $rules.maxlength(dataset.data.nome, 4)]" outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select dense :items="dataset.machineTypes" label="Tipo de máquina"
                                                v-model="dataset.data.tipo" item-value="id" item-text="name" outlined
                                                :rules="[$rules.required]" required hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="4">
                                            <v-text-field dense type="number" label="Tempo de bloqueio"
                                                v-model="dataset.data.tempoBloqueioMaquina" required outlined
                                                hide-details="auto" :rules="[$rules.required]" suffix="minuto(s)">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-currency-field label="Valor" dense v-model="dataset.data.valor" prefix="R$"
                                                :allowNegative=false hide-details="auto" outlined required
                                                :rules="[$rules.required]">
                                            </v-currency-field>
                                        </v-col>
                                        <v-col cols="12" sm="4">
                                            <v-text-field dense type="number" label="Posição" v-model="dataset.data.posicao"
                                                required outlined hide-details="auto" :rules="[$rules.required]">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-select disabled dense :items="dataset.upboxes" label="Upbox"
                                                v-model="dataset.data.upboxId" item-value="id" item-text="identificador"
                                                outlined :rules="[$rules.required]" required hide-details="auto">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-select dense :items="dataset.machineStatus" label="Status"
                                                v-model="dataset.data.habilitada" item-value="value" item-text="name"
                                                outlined :rules="[$rules.required]" required hide-details="auto">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <!-- <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field readonly disabled dense type="number" label="Ciclos"
                                                v-model="dataset.data.contadorCiclos" required outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field readonly disabled dense type="number" label="Ciclos (Parcial)"
                                                v-model="dataset.data.contadorParcialCiclos" required outlined
                                                hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row> -->
                                </v-card-text>

                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Informações</v-card-title>
                                <v-card-text>
                                    <span>
                                        <strong>
                                            Ciclos:
                                        </strong>
                                        {{ dataset.data.contadorCiclos }}
                                    </span>
                                    <br>
                                    <span class="mt-4">
                                        <strong>
                                            Ciclos (Parcial):
                                        </strong>
                                        {{ dataset.data.contadorParcialCiclos }}
                                    </span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-card :disabled="dataset.loading" class="card-form-outlined">
                                <v-card-title class="card-group-title">Ações</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <v-btn class="mr-10 mt-2" type="button" elevation="0" color="red" dark
                                                @click="sendPulse">Enviar Pulso</v-btn>
                                            <v-btn class="mt-2" outlined type="button" elevation="0" color="red" dark
                                                @click="resetCycles">Resetar ciclos</v-btn>
                                        </v-col>
                                        <!-- <v-col cols="12" sm="6">
                                            <v-select dense :items="dataset.machineTypes" label="Tipo de máquina"
                                                v-model="dataset.data.tipo" item-value="id" item-text="name" outlined
                                                :rules="[$rules.required]" required hide-details="auto">
                                            </v-select>
                                        </v-col> -->
                                    </v-row>

                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

    </v-container>
</template>

<script>
import string from "@/common/util/string"
import object from "@/common/util/object"

import machineTypes from "@/common/data/machine-types.data"
import machineStatus from "@/common/data/machine-status.data"

import axios from "axios"

export default {
    name: "MachineDetails",

    data: () => ({
        page: {
            title: "",
        },
        dataset: {
            breadcrumbItems: [],
            upboxes: [],
            machineTypes: machineTypes,
            machineStatus: machineStatus,
            data: {},
            loading: false,
        },
        dataForm: {
            validForm: true,
            updateLoading: false,
            removeLoading: false,
        },
    }),

    created() {
        this.loadData()
    },

    methods: {
        goBack() {
            this.$router.replace("/maquinas");
        },

        async loadData() {
            this.setPage();
            this.listBreadCrumbItems();

            await this.load();
            await this.loadUpbox();
        },

        setPage() {
            this.page.title = `Detalhes da máquina`;
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Listagem de máquinas",
                disabled: false,
                to: "/maquinas",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.isNew ? `Incluir` : `Detalhes da máquina`,
                disabled: true,
                exact: true,
            });
        },

        async load() {
            this.dataset.loading = true

            try {
                const result = await axios.get(`/api/maquina/${this.$route.params.id}`);

                this.dataset.data = result.data
                this.dataset.data.upboxId = this.dataset.data.upbox.id;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.loading = false;
            }
        },

        async loadUpbox() {
            this.dataset.loading = true

            try {
                const result = await axios.get(`/api/upbox`);

                this.dataset.upboxes = result.data
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.loading = false;
            }
        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: `Deseja atualizar a máquina <strong>${this.dataset.data.nome}</strong>?`,
                title: 'Atualizar máquina',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataset.loading = true;

            const payload = this.buildPayload();

            try {
                await axios.put(`/api/maquina/${this.$route.params.id}`, payload, { config: { headers: { "Content-Type": "application/json" } } });

                this.$dialog.notify.info("Máquina salva com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }
        },

        async sendPulse() {

            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: `Deseja enviar o pulso para a máquina <strong>${this.dataset.data.nome}</strong>?`,
                title: 'Pulso',
                actions: {
                    false: 'Cancelar',
                    true: 'Enviar'
                }
            })

            if (!proceedAction) return;

            this.dataset.loading = true;

            try {
                await axios.post(`/api/maquina/${this.$route.params.id}/enviar-pulso`, {});

                this.$dialog.notify.info("Pulso enviado com sucesso", { position: "top-right", timeout: 2000 });

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }
        },

        async resetCycles() {

            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: `Deseja resetar o contador da máquina <strong>${this.dataset.data.nome}</strong>?`,
                title: 'Contador',
                actions: {
                    false: 'Cancelar',
                    true: 'Resetar'
                }
            })

            if (!proceedAction) return;

            this.dataset.loading = true;

            try {
                await axios.post(`/api/maquina/${this.$route.params.id}/reset-contador-parcial`, {});

                this.$dialog.notify.info("Contador resetado com sucesso", { position: "top-right", timeout: 2000 });

                this.load()

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }
        },

        buildPayload() {
            const data = {
                nome: this.dataset.data.nome,
                tipo: this.dataset.data.tipo,
                tempoBloqueioMaquina: this.dataset.data.tempoBloqueioMaquina,
                valor: this.dataset.data.valor,
                posicao: this.dataset.data.posicao,
                upboxId: this.dataset.data.upboxId,
                habilitada: this.dataset.data.habilitada
            }

            return data;
        },




    },
};
</script>

<style lang="scss">
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}

.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    // font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
}
</style>