<template>
    <v-container class="py-12">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Totem de Pagamento</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                {
                    text: 'Homepage',
                    disabled: false,
                    to: '/home',
                    exact: true,
                },
                {
                    text: 'Totem de pagamento',
                    disabled: true,
                    exact: true,
                },
            ]"></v-breadcrumbs>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table :headers="dataList.headers" :items="dataList.data" :server-items-length="dataList.count"
                    :disable-sort="true" :loading="dataList.loading" no-data-text="Nenhuma upbox foi encontrada"
                    loading-text="Carregando dados..." class="app-table hover-table" hide-default-footer>

                    <template v-slot:[`item.name`]="{ item }">
                        {{ item.identificador }}
                    </template>

                    <template v-slot:[`item.version`]="{ item }">
                        {{ item.versao }}
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                        <span :class="item.habilitado ? 'green--text' : 'error--text'">
                            <strong>{{ item.habilitado ? "Habilitado" : "Desabilitado" }}</strong>
                        </span>
                    </template>

                    <template v-slot:[`item.lastUpdated`]="{ item }">
                        {{ item.dataUltimaComunicacao | defaultDate("DD/MM/YYYY HH:mm:ss") }}
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-row v-on="on" align="center">
                                    <v-icon @click="restartTotem(item.id)" size="large">
                                        mdi-refresh
                                    </v-icon>
                                </v-row>
                            </template>
                            <span>Reiniciar totem</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import totemStatus from "@/common/data/totem-status.data"

import { eventHub } from "@/main"

Vue.use(VueLodash)

export default {
    name: "PaymentTotemList",

    data: () => ({
        dataset: {
            totemStatus: totemStatus,
            data: {},
            operation: null
        },
        dataList: {
            filter: {
                name: "",
            },
            headers: [
                { text: "Identificador", value: "name" },
                { text: "Código Stone", value: "stoneCode" },
                { text: "Versão", value: "version" },
                { text: "Status", value: "status" },
                { text: "Última comunicação", value: "lastUpdated" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,

        },
    }),

    mounted() {

        this.getSelectedOperation();

        if (!this.dataset.operation) {
            this.$dialog.notify.error('Nenhuma operação selecionada!', { position: 'top-right', timeout: 5000 });
            return;
        }

        this.loadData();

        eventHub.$on('CHANGE-OPERATION', (value) => {
            this.getSelectedOperation();
            this.loadData()
        })
    },

    destroyed() {
        eventHub.$off('CHANGE-OPERATION')
    },

    methods: {
        getColorByStatus(status) {
            switch (status) {
                case 0:
                    return 'warning'
                case 1:
                    return 'accent'
                case 2:
                    return 'error'
                default:
                    break;
            }
        },

        getSelectedOperation() {
            this.dataset.operation = localStorage.getItem('operation');
        },

        loadData() {
            this.load()
        },

        async load() {
            this.dataList.loading = true;

            try {
                const result = await axios.get('/api/totem-pagamento', {
                    params: { operacaoId: this.dataset.operation }
                });

                this.dataList.data = result.data;
                this.dataList.count = this.dataList.data.length;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        async restartTotem(id) {

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja reiniciar o totem?',
                title: 'Totem',
                actions: {
                    false: 'Cancelar',
                    true: 'Enviar'
                }
            })

            if (!proceedAction) return;

            this.dataList.loading = true;

            try {
                await axios.post(`/api/totem-pagamento/${id}/restart`, {});

                this.$dialog.notify.info("Comando para reiniciar o totem foi enviado com sucesso!", { position: "top-right", timeout: 2000 });

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataList.loading = false;
            }
        },

        viewItem(item) {
            this.$router.push(`upboxes/${item.id}`);
        },

    },
};
</script>