import axios from 'axios'
//import LocalStorageService from "./services/storage/localstorageservice";
import router from '../router'
var parse = require('json-parse-safe')

import Keycloak from 'keycloak-js';

axios.defaults.baseURL = process.env.VUE_APP_API_URL || "http://localhost:3000";
// axios.defaults.baseURL = "https://api2.lavepay.com";

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    const data = parse(<any>localStorage.getItem('token'))

    if (data.value) {
      config.headers['Authorization'] = 'Bearer ' + data.value
    }

    config.headers['Access'] = '*'
    config.headers['Access-Control-Allow-Origin'] = '*'
    config.headers['Access-Control-Allow-Headers'] = 'Origin, Content-Type, X-Auth-Token'

    config.withCredentials = false;

    return config
  },
  error => {
    Promise.reject(error)
  }
)

//Add a response interceptor
axios.interceptors.response.use(
  response => {
    return response
  },
  function (error) {
    const originalRequest = error.config

    console.log('interceptor error ', error)

    if (error.request.status == 0) {
      return Promise.reject(error)
    }

    // if (error.response.status === 401 && !originalRequest.url.includes('/token')) {
    //   router.push('/login')
    //   return Promise.reject(error)
    // }

    if (error.response.status === 401) {

      console.log('auth error 401')

      const logoutUrl = "https://login.lavepay.com/realms/lavepay-bo/protocol/openid-connect/logout?post_logout_redirect_uri=https://portal.lavepay.com&client_id=web_app"

      window.open(logoutUrl, '_self');

      // const options = {
      //   url: 'https://login.lavepay.com', realm: 'lavepay-bo', clientId: 'web_app',
      //   onLoad: 'login-required'
      // }

      // const keycloak = Keycloak(options);
      // var isExpired = keycloak.isTokenExpired();

      // return keycloak.updateToken(1200).then((x) => {
      //   console.log('update token ', x)
      //   if (x) {
      //     localStorage.setItem('token', JSON.stringify(keycloak.token));
      //     localStorage.setItem('refresh_token', JSON.stringify(keycloak.refreshToken));
      //   }

      //   return axios(originalRequest);
      // }).catch((err) => {
      //   console.log('error ', err)
      //   return Promise.reject(error);
      // });

      // const token = parse(<any>localStorage.getItem('refresh_token'))

      // console.log('retry refresh token ', token);

      // let data = {
      //   grant_type: "refresh_token",
      //   refresh_token: token.value,
      //   client_id: "web_app"
      // };

      // const dataParams = new URLSearchParams(data);

      // return axios
      //   .post(
      //     `/realms/lavepay-bo/protocol/openid-connect/token`,
      //     dataParams,
      //     {
      //       headers: {
      //         "Content-Type": "application/x-www-form-urlencoded", "scope": "openid",
      //       },
      //       withCredentials: true,
      //     }
      //   )
      //   .then((res) => {
      //     if (res.status === 200) {
      //       localStorage.setItem('token', JSON.stringify(res.data.access_token));
      //       localStorage.setItem('refresh_token', JSON.stringify(res.data.refresh_token));

      //       axios.defaults.headers.common["Authorization"] =
      //         "Bearer " + res.data.access_token;

      //     }
      //     return axios(originalRequest);
      //   })
      //   .catch((err) => {
      //     console.error(err);
      //     return Promise.reject(error);
      //   });
    } else {
      return Promise.reject(error)
    }

    // //check error format
    // if (error.response.data && 'success' in error.response.data) {
    //   return Promise.reject(error.response.data)
    // }

  }
)
