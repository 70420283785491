import Vue, { PluginObject } from 'vue'

class CodesPlugin implements PluginObject<any> {
  install = (_Vue: typeof Vue, options?: any) => {
    _Vue.prototype.$codes = function (object: any) {
      var message = ''

      if (object && object.response && object.response.data) {
        message = object.response.data.detalhe || 'nenhuma informação disponível'
      }

      return message
    }
  };
  [key: string]: any
}

const plugin = new CodesPlugin()
Vue.use(plugin)
