<template>
    <v-container class="py-12">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Máquinas</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                {
                    text: 'Homepage',
                    disabled: false,
                    to: '/home',
                    exact: true,
                },
                {
                    text: 'Máquinas',
                    disabled: true,
                    exact: true,
                },
            ]"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn type="button" elevation="0" color="primary" dark @click="loadData"
                            :loading="dataset.loading">Atualizar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table :headers="dataList.headers" :items="dataList.data" :server-items-length="dataList.count"
                    :disable-sort="true" :loading="dataList.loading" no-data-text="Nenhuma máquina foi encontrada"
                    loading-text="Carregando dados..." class="app-table hover-table" hide-default-footer>

                    <template v-slot:[`item.name`]="{ item }">
                        {{ item.nome }}
                    </template>

                    <template v-slot:[`item.type`]="{ item }">
                        {{ item.tipo }}
                    </template>

                    <template v-slot:[`item.position`]="{ item }">
                        {{ item.posicao }}
                    </template>

                    <template v-slot:[`item.upbox`]="{ item }">
                        {{ item.upbox ? item.upbox.identificador : '' }}
                    </template>

                    <template v-slot:[`item.blockTime`]="{ item }">
                        {{ item.tempoBloqueioMaquina }}min
                    </template>

                    <template v-slot:[`item.value`]="{ item }">
                        R$ {{ item.valor | currency }}
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                        <span :class="item.habilitada ? 'green--text' : 'error--text'">
                            <strong>{{ item.habilitada ? "Habilitada" : "Desabilitada" }}</strong>
                        </span>
                    </template>

                    <template v-slot:[`item.statusMaquina`]="{ item }">
                        <span>
                            <v-icon class="mr-2" x-small
                                :color="getColorByUpboxStatus(item.statusMaquina)">mdi-circle</v-icon>
                            {{ item.statusMaquina || 'N/A' }}
                        </span>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-row align="center">
                            <v-icon @click="viewItem(item)" size="large">
                                mdi-pencil-outline
                            </v-icon>
                        </v-row>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import object from "@/common/util/object"

import { eventHub } from "@/main"

Vue.use(VueLodash)

export default {
    name: "MachineList",

    data: () => ({
        dataset: {
            data: {},
            operation: null
        },
        dataList: {
            filter: {
                name: "",
            },
            headers: [
                { text: "Nome", value: "name" },
                { text: "Tipo", value: "type" },
                { text: "Valor", value: "value" },
                { text: "Tempo de bloqueio", value: "blockTime" },
                { text: "Posição", value: "position" },
                { text: "Upbox", value: "upbox" },
                { text: "Situação", value: "status" },
                { text: "Status", value: "statusMaquina" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
        },
    }),

    mounted() {
        eventHub.$on('CHANGE-OPERATION', (value) => {
            this.getSelectedOperation();
            this.loadData()
        })

        this.getSelectedOperation();

        if (!this.dataset.operation) {
            this.$dialog.notify.error('Nenhuma operação selecionada!', { position: 'top-right', timeout: 5000 });
            return;
        }

        this.loadData();
    },

    destroyed() {
        eventHub.$off('CHANGE-OPERATION')
    },

    methods: {

        getColorByUpboxStatus(status) {
            if (!status) return 'mediumGray';

            switch (status.toLowerCase()) {
                case 'livre':
                    return 'green'
                case 'offline':
                    return 'error'
                case 'ocupada':
                    return 'amber'
                default:
                    return 'error'
            }
        },

        getSelectedOperation() {
            this.dataset.operation = localStorage.getItem('operation');
        },

        loadData() {
            this.load()
        },

        async load() {
            this.dataList.loading = true;

            try {
                const result = await axios.get('/api/maquina', {
                    params: { operacaoId: this.dataset.operation }
                });

                this.dataList.data = result.data;
                this.dataList.count = this.dataList.data.length;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        async testRequest() {
            const result = await axios.post('https://demo6718810.mockable.io/test-error');
        },

        viewItem(item) {
            this.$router.push(`maquinas/${item.id}`);
        },

    },
};
</script>