<template>
    <v-container class="py-12">
        <v-row class="d-none d-sm-flex">
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="update"
                            :loading="dataset.loading">Salvar</v-btn>
                        <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined
                            to="/upboxes">Voltar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>

        <v-row class="d-sm-none d-lg-none d-md-flex">
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span class="page-title">{{ page.title }}</span>
                    </div>
                </div>
                <div>
                    <v-breadcrumbs class="app-breadcrumb" divider="›" :items="dataset.breadcrumbItems"></v-breadcrumbs>
                </div>
            </v-col>
        </v-row>

        <v-row class="d-sm-none d-lg-none d-md-flex">
            <v-col>
                <div class="button-group">
                    <v-btn id="btnSave" type="button" elevation="0" color="primary" dark @click="update"
                        :loading="dataset.loading">Salvar</v-btn>
                    <v-btn id="btnBack" type="button" elevation="0" color="primary" outlined to="/upboxes">Voltar</v-btn>
                </div>
            </v-col>
        </v-row>

        <v-form ref="form" v-model="dataForm.validForm">
            <v-row>
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">Detalhes</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field readonly disabled dense label="Identificador"
                                                v-model="dataset.data.identificador" required :rules="[$rules.required]"
                                                outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field readonly disabled dense label="Mac" v-model="dataset.data.mac"
                                                required hide-details="auto" outlined>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense type="number" label="Valor pulso"
                                                v-model="dataset.data.valorPulso" required outlined hide-details="auto"
                                                :rules="[$rules.required]" suffix="milisegundo(s)">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field readonly disabled dense type="number" label="Quantidade de canais"
                                                v-model="dataset.data.quantidadeCanais" required outlined
                                                hide-details="auto" :rules="[$rules.required]">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <v-text-field dense type="number" min="0" max="100" label="Quantidade de pulsos"
                                                v-model="dataset.data.quantidadePulsos" required outlined
                                                hide-details="auto" :rules="[$rules.required]">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-text-field min="0" max="10000" dense type="number" label="Delay"
                                                v-model="dataset.data.delayPulsos" outlined hide-details="auto">
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-card class="card-form-outlined">
                                <v-card-title class="card-group-title">
                                    <v-row>
                                        <v-col>
                                            <span>
                                                Informações
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="12" sm="6">
                                            <span v-if="dataset.loadingInfos">
                                                Carregando informações...
                                            </span>
                                            <span v-if="!dataset.loadingInfos && dataset.errorUpboxInfo">
                                                {{ dataset.errorUpboxInfo }}
                                            </span>
                                            <span v-if="!dataset.loadingInfos && dataset.dataInfo">
                                                <strong>IP: </strong>{{ dataset.dataInfo.ip }}
                                                <br>
                                                <strong>Versão: </strong> {{ dataset.dataInfo.versao }}
                                                <br>
                                                <strong>Status: </strong> {{ dataset.dataInfo.status }}
                                                <br>
                                                <strong>Data: </strong> {{
                                                    $moment(dataset.dataInfo.data).format("DD/MM/YYYY HH:mm") }}
                                                <br>
                                                <strong>Sinal: </strong>{{ getTextBySignal(dataset.dataInfo.forcaSinal)
                                                }} <v-icon :color="getIconColorBySignal(dataset.dataInfo.forcaSinal)">
                                                    {{ getIconBySignal(dataset.dataInfo.forcaSinal) }}</v-icon>
                                            </span>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-text>
                                    <v-btn type="button" outlined elevation="0" color="primary" dark
                                        @click="loadUpboxInfo()" :loading="dataset.loadingInfos">
                                        <v-icon class="mr-2">
                                            mdi-refresh
                                        </v-icon>
                                        Solicitar Infos
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-form>

    </v-container>
</template>

<script>
import string from "@/common/util/string"
import object from "@/common/util/object"

import axios from "axios"

export default {
    name: "UpboxDetails",

    data: () => ({
        page: {
            title: "",
        },
        dataset: {
            data: {},
            dataInfo: null,
            breadcrumbItems: [],
            loading: false,
            loadingInfos: false,
            errorUpboxInfo: null
        },
        dataForm: {
            validForm: true,
        },
    }),

    created() {
        this.loadData()
    },

    methods: {
        goBack() {
            this.$router.replace("/upboxes");
        },

        async loadData() {
            this.setPage();
            this.listBreadCrumbItems();

            await this.load();
        },

        setPage() {
            this.page.title = `Detalhes da upbox`;
        },

        listBreadCrumbItems() {
            this.dataset.breadcrumbItems = [
                {
                    text: "Homepage",
                    disabled: false,
                    to: "/home",
                    exact: true,
                },
            ];

            this.dataset.breadcrumbItems.push({
                text: "Listagem de upbox",
                disabled: false,
                to: "/upboxes",
                exact: true,
            })

            this.dataset.breadcrumbItems.push({
                text: this.page.isNew ? `Incluir` : `Detalhes da upbox`,
                disabled: true,
                exact: true,
            });
        },

        async load() {
            this.dataset.loading = true

            try {
                const result = await axios.get(`/api/upbox/${this.$route.params.id}`);

                this.dataset.data = result.data

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.loading = false;
            }
        },

        async loadUpboxInfo() {
            this.dataset.loadingInfos = true

            this.dataset.errorUpboxInfo = null;
            this.dataset.dataInfo = null;

            try {
                const result = await axios.get(`/api/upbox/${this.$route.params.id}/info`);

                this.dataset.dataInfo = result.data

            } catch (error) {
                if (error.response && error.response.data.detalhe)
                    this.dataset.errorUpboxInfo = error.response.data.detalhe

                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.loadingInfos = false;
            }
        },

        async update() {
            if (!this.$refs.form.validate()) return;

            const proceedAction = await this.$dialog.confirm({
                text: 'Deseja atualizar a upbox?',
                title: 'Atualizar upbox',
                actions: {
                    false: 'Cancelar',
                    true: 'Atualizar'
                }
            })

            if (!proceedAction) return;

            this.dataset.loading = true;

            const payload = this.buildPayload();

            try {
                const response = await axios.put(`/api/upbox/${this.$route.params.id}`, payload, { config: { headers: { "Content-Type": "application/json" } } });

                this.$dialog.notify.info("Upbox salva com sucesso", { position: "top-right", timeout: 2000 });

                this.goBack()
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), {
                    position: 'top-right',
                    timeout: 2000
                })
            } finally {
                this.dataset.loading = false;
            }
        },

        getTextBySignal(signal) {
            switch (signal) {
                case 1:
                    return 'Muito ruim'
                case 2:
                    return 'Ruim'
                case 3:
                    return 'Bom'
                case 4:
                    return 'Ótimo'
                case 5:
                    return 'Excelente'
                default:
                    break;
            }
        },

        getIconBySignal(signal) {
            switch (signal) {
                case 1:
                    return 'mdi-wifi-strength-alert-outline'
                case 2:
                    return 'mdi-wifi-strength-1'
                case 3:
                    return 'mdi-wifi-strength-2'
                case 4:
                    return 'mdi-wifi-strength-3'
                case 5:
                    return 'mdi-wifi-strength-4'
                default:
                    break;
            }
        },

        getIconColorBySignal(signal) {
            switch (signal) {
                case 1:
                case 2:
                    return 'error'
                case 3:
                    return 'amber'
                case 4:
                case 5:
                    return 'green'
                default:
                    break;
            }
        },

        buildPayload() {
            let data = {
                identificador: this.dataset.data.identificador,
                mac: this.dataset.data.mac,
                quantidadeCanais: this.dataset.data.quantidadeCanais,
                valorPulso: this.dataset.data.valorPulso,
                quantidadePulsos: parseInt(this.dataset.data.quantidadePulsos.toString()),
                operacaoId: this.dataset.data.operacao.id
            }

            if (this.dataset.data.delayPulsos) {
                data.delayPulsos = parseInt(this.dataset.data.delayPulsos.toString())
            }

            return data;
        },




    },
};
</script>

<style lang="scss">
.access-container {
    border: 1px solid var(--v-gray-base) !important;
}

.custom-chip {
    background: var(--v-white-base) !important;
}

.title-page {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
}

.section-info-title {
    color: var(--v-primary-base);
    // font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
}

.section-info-value {
    // font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
}
</style>