var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"py-12"},[_c('v-row',[_c('v-col',{attrs:{"cols":12}},[_c('div',{staticClass:"title-container"},[_c('div',{staticClass:"title-block"},[_c('span',{staticClass:"page-title",attrs:{"id":"title"}},[_vm._v("Máquinas")]),_c('v-breadcrumbs',{staticClass:"app-breadcrumb",attrs:{"divider":"›","items":[
            {
                text: 'Homepage',
                disabled: false,
                to: '/home',
                exact: true,
            },
            {
                text: 'Máquinas',
                disabled: true,
                exact: true,
            } ]}})],1),_c('div',{staticClass:"button-group"},[_c('v-btn',{attrs:{"type":"button","elevation":"0","color":"primary","dark":"","loading":_vm.dataset.loading},on:{"click":_vm.loadData}},[_vm._v("Atualizar")])],1)])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"app-table hover-table",attrs:{"headers":_vm.dataList.headers,"items":_vm.dataList.data,"server-items-length":_vm.dataList.count,"disable-sort":true,"loading":_vm.dataList.loading,"no-data-text":"Nenhuma máquina foi encontrada","loading-text":"Carregando dados...","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.nome)+" ")]}},{key:"item.type",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipo)+" ")]}},{key:"item.position",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.posicao)+" ")]}},{key:"item.upbox",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.upbox ? item.upbox.identificador : '')+" ")]}},{key:"item.blockTime",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.tempoBloqueioMaquina)+"min ")]}},{key:"item.value",fn:function(ref){
        var item = ref.item;
return [_vm._v(" R$ "+_vm._s(_vm._f("currency")(item.valor))+" ")]}},{key:"item.status",fn:function(ref){
        var item = ref.item;
return [_c('span',{class:item.habilitada ? 'green--text' : 'error--text'},[_c('strong',[_vm._v(_vm._s(item.habilitada ? "Habilitada" : "Desabilitada"))])])]}},{key:"item.statusMaquina",fn:function(ref){
        var item = ref.item;
return [_c('span',[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":_vm.getColorByUpboxStatus(item.statusMaquina)}},[_vm._v("mdi-circle")]),_vm._v(" "+_vm._s(item.statusMaquina || 'N/A')+" ")],1)]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-row',{attrs:{"align":"center"}},[_c('v-icon',{attrs:{"size":"large"},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" mdi-pencil-outline ")])],1)]}}],null,true)}),_c('v-divider')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }