import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
var parse = require('json-parse-safe')

import { Subject } from 'rxjs'
import VueMask from 'v-mask'

import * as VueGoogleMaps from 'vue2-google-maps'
import VueClipboard from 'vue-clipboard2'

import axios from 'axios'

export const eventHub = new Vue()

import './plugins/axios'
import './plugins/vuetify-dialog'
import './plugins/codes'
import './plugins/auth-image'
import './plugins/moment'
// import './plugins/highcharts'
import './plugins/currency'
import './plugins/currency-field'
import './plugins/types'
import './plugins/maps'
import './plugins/functions'
import './plugins/tooltip'
import './plugins/validation'
import './plugins/permissions'

import '@/assets/scss/main.scss'

import VueHtmlToPaper from 'vue-html-to-paper';

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
// Vuetify's CSS styles 
import 'vuetify/dist/vuetify.min.css'

import './common/filters/defaultDate.filter';

import Keycloak from 'keycloak-js';
import * as jwt from "jwt-decode";

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)

Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})

Vue.use(VueHtmlToPaper);

Vue.config.productionTip = false

Vue.filter('enum', (value, data, valueProperty, textProperty) => {
  if (!valueProperty) {
    valueProperty = 'id'
  }
  if (!textProperty) {
    textProperty = 'name'
  }

  let item = data.find(item => item[valueProperty] === value)

  if (item) {
    return item[textProperty]
  }

  return value
})

let initOptions = {
  url: 'https://login.lavepay.com', realm: 'lavepay-bo', clientId: 'web_app',
  onLoad: 'login-required'
  // onLoad: 'check-sso',
  // redirectUri: "http://localhost:8080/*"
}

let keycloak = Keycloak(initOptions);
Vue.prototype.$keycloak = keycloak

function initKeycloak() {
  keycloak.init({ onLoad: 'login-required' }).then((x) => {
    console.log('result success login ', x);

    setInterval(() => {
      keycloak.updateToken(5).then((success) => { console.log('keycloak token ', success) });
    }, 20000)

    if (!x) {
      window.location.reload();
    }

    mountAppVue()

    let payload = {
      idToken: keycloak.idToken,
      accessToken: keycloak.token
    }

    console.log('payload ', payload)

    if (keycloak.token && keycloak.idToken && keycloak.token != '' && keycloak.idToken != '') {
      // store.commit("login", payload);
      console.log("User has logged in: " + keycloak.subject)

      localStorage.setItem('token', JSON.stringify(keycloak.token));
      localStorage.setItem('refresh_token', JSON.stringify(keycloak.refreshToken));

      var dataUser = jwt(keycloak.token);

      console.log('dataUser', dataUser)

      localStorage.setItem('user', JSON.stringify(dataUser));

      router.push("/home");
    }
    else {

      // store.commit("logout");
    }

  }).catch((err) => {
    console.log('error login ', err)
  })
}

initKeycloak();

keycloak.onAuthRefreshError = () => {
  initKeycloak();
}

keycloak.onTokenExpired = () => {
  keycloak.updateToken(1).then((x) => {
    console.log('token is updated ', x)

    localStorage.setItem('token', JSON.stringify(keycloak.token));
    localStorage.setItem('refresh_token', JSON.stringify(keycloak.refreshToken));

  }).catch((err) => console.log('error updated token ', err));
}

Vue.use(VueMask)
Vue.use(VueClipboard)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCVFdT_WivbZ1Cxyvl8LJ61dGFw8qpwgrk',
    libraries: 'places'
  }
})

function mountAppVue() {
  new Vue({
    router,
    vuetify,
    render: h => h(App),
    data: {
      title: '-',
      user: {},
      actions: {
        suggest: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        download: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        publish: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        history: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        import: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        upload: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        address: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        sync: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        productivity: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        create: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        remove: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        edit: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        editnew: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        save: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        group: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        add: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        filter: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        store: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        },
        file: {
          visible: false,
          disable: false,
          loading: false,
          event: new Subject()
        }
      }
    },
    methods: {
      show: function (action: any) {
        this.actions.suggest.visible = !!action.suggest
        this.actions.download.visible = !!action.download
        this.actions.publish.visible = !!action.publish
        this.actions.history.visible = !!action.history
        this.actions.import.visible = !!action.import
        this.actions.upload.visible = !!action.upload
        this.actions.address.visible = !!action.address
        this.actions.sync.visible = !!action.sync
        this.actions.productivity.visible = !!action.productivity
        this.actions.create.visible = !!action.create
        this.actions.remove.visible = !!action.remove
        this.actions.editnew.visible = !!action.editnew
        this.actions.edit.visible = !!action.edit
        this.actions.save.visible = !!action.save
        this.actions.group.visible = !!action.group
        this.actions.add.visible = !!action.add
        this.actions.filter.visible = !!action.filter
        this.actions.store.visible = !!action.store
        this.actions.file.visible = !!action.file

        //reset all the buttons that are visible
        for (var key in this.actions) {
          if (this.actions[key].visible) {
            this.actions[key].disable = false
            this.actions[key].loading = false
            this.actions[key].disable = false
            this.actions[key].event = new Subject()
          }
        }
      }
    },
    created: function () {
      this.user = parse(localStorage.getItem('user')).value
    }
  }).$mount('#app')
}
