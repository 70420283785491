









import Vue from "vue";

//import Home from "./views/Home.vue";

export default Vue.extend({
    name: "App",

    components: {
        //Home
    },

    data: () => ({
        drawer: false,
    }),

    methods: {
        open: function () {
            this.$data.drawer = !this.$data.drawer;
        },

    },
});
