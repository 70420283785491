<template>
    <v-container class="py-12">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Upbox</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'Upbox',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>
                    <div class="button-group">
                        <v-btn type="button" elevation="0" color="primary" dark @click="loadData"
                            :loading="dataset.loading">Atualizar</v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table :headers="dataList.headers" :items="dataList.data" :server-items-length="dataList.count"
                    :disable-sort="true" :loading="dataList.loading" no-data-text="Nenhuma upbox foi encontrada"
                    loading-text="Carregando dados..." class="app-table hover-table" hide-default-footer>

                    <template v-slot:[`item.name`]="{ item }">
                        {{ item.identificador }}
                    </template>

                    <template v-slot:[`item.mac`]="{ item }">
                        {{ item.mac }}
                    </template>

                    <template v-slot:[`item.totalChannels`]="{ item }">
                        {{ item.quantidadeCanais }}
                    </template>

                    <template v-slot:[`item.pulseValue`]="{ item }">
                        {{ item.valorPulso }}
                    </template>

                    <template v-slot:[`item.lastUpdated`]="{ item }">
                        {{ item.dataUltimaComunicacao | defaultDate("DD/MM/YYYY HH:mm:ss") }}
                    </template>

                    <template v-slot:[`item.status`]="{ item }">
                        <span>
                            <v-icon class="mr-2" x-small :color="getColorByStatus(item.status)">mdi-circle</v-icon>
                            {{ item.status ? getTextByStatus(item.status) : 'N/A' }}
                        </span>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-row align="center">
                            <v-icon @click="viewItem(item)" size="large">
                                mdi-pencil-outline
                            </v-icon>
                        </v-row>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import { eventHub } from '@/main'

Vue.use(VueLodash)

export default {
    name: "MachineList",

    data: () => ({
        dataset: {
            data: {},
            operation: null
        },
        dataList: {
            filter: {
                name: "",
            },
            headers: [
                { text: "Identificador", value: "name" },
                { text: "MAC", value: "mac" },
                { text: "Pulso", value: "pulseValue" },
                { text: "Canais", value: "totalChannels" },
                { text: "Última comunicação", value: "lastUpdated" },
                { text: "Status", value: "status" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,

        },
    }),

    mounted() {

        this.getSelectedOperation();

        if (!this.dataset.operation) {
            this.$dialog.notify.error('Nenhuma operação selecionada!', { position: 'top-right', timeout: 5000 });
            return;
        }

        this.loadData();

        eventHub.$on('CHANGE-OPERATION', (value) => {
            this.getSelectedOperation();
            this.loadData()
        })
    },

    destroyed() {
        eventHub.$off('CHANGE-OPERATION')
    },

    methods: {
        getColorByStatus(status) {
            if (!status) return 'mediumGray';

            switch (status.toLowerCase()) {
                case 'ocupada':
                case 'livre':
                    return 'green'
                case 'offline':
                    return 'error'
                default:
                    return 'error'
            }
        },

        getTextByStatus(status) {
            if (!status) return 'mediumGray';

            switch (status.toLowerCase()) {
                case 'ocupada':
                case 'livre':
                    return 'ONLINE'
                case 'offline':
                default:
                    return 'OFFLINE'
            }
        },

        getSelectedOperation() {
            this.dataset.operation = localStorage.getItem('operation');
        },

        loadData() {
            this.load()
        },

        async load() {
            this.dataList.loading = true;

            try {
                const result = await axios.get('/api/upbox', {
                    params: { operacaoId: this.dataset.operation }
                });

                this.dataList.data = result.data;
                this.dataList.count = this.dataList.data.length;

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        viewItem(item) {
            this.$router.push(`upboxes/${item.id}`);
        },

    },
};
</script>