<template>
    <v-container class="py-12">
        <v-row>
            <v-col :cols="12">
                <div class="title-container">
                    <div class="title-block">
                        <span id="title" class="page-title">Histórico de Vendas</span>
                        <v-breadcrumbs class="app-breadcrumb" divider="›" :items="[
                            {
                                text: 'Homepage',
                                disabled: false,
                                to: '/home',
                                exact: true,
                            },
                            {
                                text: 'Histórico de vendas',
                                disabled: true,
                                exact: true,
                            },
                        ]"></v-breadcrumbs>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card :loading="dataset.loading" class="home-card-details">
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <span class="text-h6 mr-4 home-card-details-title">
                                    Filtros
                                </span>
                            </v-col>
                        </v-row>
                        <v-row align="center">
                            <v-col>
                                <v-select :disabled="dataset.loading" v-on:change="normalizeRangeByType" dense
                                    :items="dataset.periodTypes" label="Período" v-model="dataset.filterSelected"
                                    item-value="type" item-text="label" outlined :rules="[$rules.required]" required
                                    hide-details="auto">
                                </v-select>
                            </v-col>
                            <v-col>
                                <v-text-field v-model="dataset.rangeDate.startDate" type="datetime-local"
                                    :disabled="dataset.filterSelected != 9" dense label="Data início" required outlined
                                    hide-details>
                                </v-text-field>
                            </v-col>

                            <v-col>
                                <v-text-field v-model="dataset.rangeDate.endDate" type="datetime-local"
                                    :disabled="dataset.filterSelected != 9" dense label="Data fim" required outlined
                                    hide-details>
                                </v-text-field>
                            </v-col>

                            <v-col>
                                <v-select dense :items="dataset.paymentTypes" label="Tipo de pagamento"
                                    v-model="dataset.filter.paymentType" item-value="id" item-text="name" outlined
                                    hide-details="auto">
                                </v-select>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col>
                                <div class="button-group">
                                    <v-btn :loading="dataset.loading" type="button" elevation="0" color="primary"
                                        @click="loadData()">Filtrar</v-btn>
                                    <v-btn :disabled="dataset.loading" type="button" elevation="0" outlined color="primary"
                                        @click="exportReport()">Exportar</v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table :headers="dataList.headers" :items="dataList.data" :server-items-length="dataList.count"
                    @update:items-per-page="pageSizeChanged" @update:page="pageChanged" :disable-sort="true"
                    :loading="dataList.loading" no-data-text="Nenhuma venda foi encontrada"
                    loading-text="Carregando dados..." class="app-table hover-table" :options.sync="dataList.options"
                    :footer-props="dataList.footerOptions">

                    <template v-slot:[`item.date`]="{ item }">
                        {{ item.data | defaultDate("DD/MM/YYYY HH:mm:ss") }}
                    </template>

                    <template v-slot:[`item.amount`]="{ item }">
                        R$ {{ item.valor | currency }}
                    </template>

                    <template v-slot:[`item.description`]="{ item }">
                        {{ getSaleDescription(item.vendaItens) }}
                    </template>

                    <template v-slot:[`item.paymentType`]="{ item }">
                        {{ item.tipoPagamento | enum(dataset.paymentTypes, 'id', 'name') }}
                    </template>

                    <template v-slot:[`item.flag`]="{ item }">
                        <img :src="getFlagIcon(item.tipoPagamentoBandeira)" class="flag-icon" />
                    </template>

                    <!-- <template v-slot:[`item.status`]="{ item }">
                        <v-chip :color="item.habilitado ? 'green' : 'error'" outlined>
                            <strong>
                                {{ item.habilitado | enum(dataset.totemStatus, "value", "name") }}
                            </strong>
                        </v-chip>
                    </template>

                    <template v-slot:[`item.lastUpdated`]="{ item }">
                        {{ item.dataUltimaComunicacao | defaultDate("DD/MM/YYYY HH:mm:ss") }}
                    </template> -->

                    <template v-slot:[`item.actions`]="{ item }">
                        <v-row align="center">
                            <v-icon @click="viewItem(item)" size="large">
                                mdi-eye-outline
                            </v-icon>

                            <v-icon class="ml-2" @click="viewProof(item)" size="large">
                                mdi-invoice-list-outline
                            </v-icon>
                        </v-row>
                    </template>
                </v-data-table>
                <v-divider></v-divider>
            </v-col>
        </v-row>

        <v-dialog v-if="dataset.selectedSale" v-model="dataset.dialog" :width="800">
            <v-card class='pa-3'>
                <v-card-title>
                    <v-row justify="center">
                        <v-col lg="12">
                            <h1 class="custom-modal-title text-uppercase black--text font-weight-bold">
                                Resumo da venda
                            </h1>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row no-gutters>
                        <v-col>
                            <v-row>
                                <v-col>
                                    <span>Data:
                                        <strong> {{ dataset.selectedSale.data | defaultDate("DD/MM/YYYY HH:mm:ss")
                                        }}</strong>
                                    </span>
                                    <br>
                                    <span>Tipo de pagamento:
                                        <strong> {{ dataset.selectedSale.tipoPagamento | enum(dataset.paymentTypes,
                                            'id',
                                            'name') }}</strong>
                                    </span>
                                    <br>
                                    <span>Bandeira:
                                        <strong> {{ dataset.selectedSale.tipoPagamentoBandeira | enum(dataset.flagTypes,
                                            'id', 'name') }}</strong>
                                    </span>
                                    <br>
                                    <div class="mt-8" v-for="(item, index) in dataset.selectedSale.vendaItens" :key="index">
                                        <span>
                                            Item <strong>#{{ index + 1 }}</strong>
                                        </span>
                                        <br>
                                        <span>
                                            Descrição: <strong>{{ item.descricao }}</strong>
                                        </span>
                                        <br>
                                        <span>
                                            Valor: <strong>R$ {{ item.valor | currency }}</strong>
                                        </span>
                                    </div>

                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary darken-1" text @click="dataset.dialog = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-if="dataset.selectedSale" v-model="dataset.dialogProof" :width="450">
            <v-card class='pa-3'>
                <v-card-title>
                    <v-row justify="center">
                        <v-col lg="12">
                            <h1 class="custom-modal-title text-uppercase black--text font-weight-bold">
                                Comprovante
                            </h1>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-tabs v-model="dataset.selectedProof">
                        <v-tab>Cliente</v-tab>
                        <v-tab>Empresa</v-tab>
                    </v-tabs>
                </v-card-text>
                <v-card-text ref="proofDiv">
                    <div class="d-none d-sm-flex proof-container">
                        <span class="text-block">
                            {{ dataset.selectedProof == 0 ? dataset.selectedSale.comprovanteCliente :
                                dataset.selectedSale.comprovanteEmpresa }}
                        </span>
                    </div>
                    <div class="d-sm-none d-lg-none d-md-flex proof-container">
                        <span class="text-block-mobile">
                            {{ dataset.selectedProof == 0 ? dataset.selectedSale.comprovanteCliente :
                                dataset.selectedSale.comprovanteEmpresa }}
                        </span>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" dark @click="printProof(dataset.selectedProof == 0 ? dataset.selectedSale.comprovanteCliente :
                        dataset.selectedSale.comprovanteEmpresa)">Imprimir</v-btn>
                    <v-btn color="primary darken-1" text @click="dataset.dialogProof = false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </v-container>
</template>

<script>

import Vue from "vue";
import VueLodash from 'vue-lodash'
import _ from 'lodash';
import axios from 'axios';

import paymentTypes from "@/common/data/payment-types.data"
import flagTypes from "@/common/data/flag-types.data"

import { eventHub } from "@/main"
import { jsPDF } from "jspdf";

Vue.use(VueLodash)

export default {
    name: "PaymentTotemList",

    data: () => ({
        dataset: {
            paymentTypes: [{ "id": null, "name": "Todos" }, ...paymentTypes],
            flagTypes: flagTypes,
            data: {},
            operation: null,
            dialog: false,
            dialogProof: false,
            selectedSale: null,
            filterSelected: 1,
            selectedProof: 0,
            loading: false,
            rangeDate: { startDate: null, endDate: null },
            datePickerStartDialog: false,
            datePickerEndDialog: false,
            filter: {
                name: "",
                paymentType: null,
            },
            periodTypes: [
                {
                    label: 'Hoje',
                    type: 1
                },
                {
                    label: 'Ontem',
                    type: 2
                },
                {
                    label: '7 dias',
                    type: 3
                },
                {
                    label: '15 dias',
                    type: 4
                },
                {
                    label: '30 dias',
                    type: 5
                },
                {
                    label: 'Esse mês',
                    type: 6
                },
                {
                    label: 'Mês passado',
                    type: 7
                },
                {
                    label: 'Últimos 3 meses',
                    type: 8
                },
                {
                    label: 'Personalizado',
                    type: 9
                },
            ]
        },
        dataList: {
            headers: [
                { text: "Data", value: "date" },
                { text: "Valor", value: "amount" },
                { text: "Descrição", value: "description" },
                { text: "Tipo de pagamento", value: "paymentType" },
                { text: "Bandeira", value: "flag" },
                { text: 'Ações', value: 'actions', sortable: false },
            ],
            data: [],
            loading: false,
            count: 0,
            options: {
                page: 1,
                size: 10,
                sortBy: ['name'],
                sortDesc: [false],
            },
            footerOptions: {
                itemsPerPageText: 'Itens por página:',
                itemsPerPageOptions: [10, 20],
                // disableItemsPerPage: true,
                pageText: '{0}-{1} de {2}',
            },

        },
    }),

    mounted() {

        this.getSelectedOperation();

        if (!this.dataset.operation) {
            this.$dialog.notify.error('Nenhuma operação selecionada!', { position: 'top-right', timeout: 5000 });
            return;
        }

        this.loadData();

        eventHub.$on('CHANGE-OPERATION', (value) => {
            this.getSelectedOperation();
            this.loadData()
        })
    },

    destroyed() {
        eventHub.$off('CHANGE-OPERATION')
    },

    methods: {
        getColorByStatus(status) {
            switch (status) {
                case 0:
                    return 'warning'
                case 1:
                    return 'accent'
                case 2:
                    return 'error'
                default:
                    break;
            }
        },

        pageChanged(page) {
            this.dataList.options.page = page;
            this.load();
        },

        pageSizeChanged(size) {
            this.dataList.options.size = size;
            this.load(true);
        },

        getSaleDescription(itens) {
            return itens.map((x) => x.descricao).join(', ')
        },

        printProof(proof) {
            const normalizedProof = proof.toString().replace(/^\s+|\s+$/gm, '');

            const doc = new jsPDF('p', 'mm', [120, 110]);

            doc.setFillColor(255, 255, 210);
            doc.rect(0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight(), 'F');


            doc.setFont("Courier New")
            doc.setFontSize(12);
            doc.text(normalizedProof, 10, 20)

            doc.save("comprovante.pdf");
        },

        getSelectedOperation() {
            this.dataset.operation = localStorage.getItem('operation');
        },

        loadData() {
            this.load()
        },

        getFlagIcon(flag) {
            const resultFlag = this.dataset.flagTypes.find((x) => x.id == flag);

            return resultFlag ? require(`../../assets/icons/${resultFlag.icon}`) : '';
        },

        async load(size = 10) {
            this.dataList.loading = true;

            this.normalizeRangeByType();

            try {

                let params = { dataInicial: this.dataset.rangeDate.startDate, operacaoId: this.dataset.operation, dataFinal: this.dataset.rangeDate.endDate, page: this.dataList.options.page - 1, size: this.dataList.options.size };

                if (this.dataset.filter.paymentType) params.tipoPagamento = this.dataset.filter.paymentType;

                const result = await axios.get('/api/venda', {
                    params
                });

                this.dataList.data = result.data.content;
                this.dataList.count = result.data.totalElements;
                // this.dataList.options.page = result.data.pageNumber + 1

            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataList.loading = false;
            }

        },

        async exportReport() {

            this.dataset.loading = true;

            try {

                let params = { dataInicial: this.dataset.rangeDate.startDate, operacaoId: this.dataset.operation, dataFinal: this.dataset.rangeDate.endDate };

                if (this.dataset.filter.paymentType) params.tipoPagamento = this.dataset.filter.paymentType;

                const result = await axios.get(`/api/venda/relatorio/export-to-excel`, { params, responseType: 'arraybuffer' });

                let blob = new Blob([result.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                let link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = 'Relatório de vendas.xlsx'
                link.click()

                this.$dialog.notify.info("Vendas exportas com sucesso!", { position: "top-right", timeout: 2000 });
            } catch (error) {
                this.$dialog.notify.error(this.$codes(error), { position: 'top-right', timeout: 5000 });
            } finally {
                this.dataset.loading = false;
            }
            // const href = URL.createObjectURL(result.data);

            // // create "a" HTML element with href to file & click
            // const link = document.createElement('a');
            // link.href = href;
            // link.setAttribute('download', 'file.pdf'); //or any other extension
            // document.body.appendChild(link);
            // link.click();

            // // clean up "a" element & remove ObjectURL
            // document.body.removeChild(link);
            // URL.revokeObjectURL(href);

            // this.resolveChartLastUsers(result.data.content)
        },

        normalizeRangeByType() {
            switch (this.dataset.filterSelected) {
                case 1:
                    this.dataset.rangeDate.startDate = this.$moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
                    this.dataset.rangeDate.endDate = this.$moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                case 2:
                    this.dataset.rangeDate.startDate = this.$moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
                    this.dataset.rangeDate.endDate = this.$moment().subtract(1, 'd').endOf('day').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                case 3:
                    this.dataset.rangeDate.startDate = this.$moment().subtract(7, 'd').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
                    this.dataset.rangeDate.endDate = this.$moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                case 4:
                    this.dataset.rangeDate.startDate = this.$moment().subtract(15, 'd').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
                    this.dataset.rangeDate.endDate = this.$moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                case 5:
                    this.dataset.rangeDate.startDate = this.$moment().subtract(30, 'd').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
                    this.dataset.rangeDate.endDate = this.$moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                case 6:
                    this.dataset.rangeDate.startDate = this.$moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss');
                    this.dataset.rangeDate.endDate = this.$moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                case 7:
                    this.dataset.rangeDate.startDate = this.$moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DDTHH:mm:ss');
                    this.dataset.rangeDate.endDate = this.$moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                case 8:
                    this.dataset.rangeDate.startDate = this.$moment().subtract(3, 'month').format('YYYY-MM-DDTHH:mm:ss');
                    this.dataset.rangeDate.endDate = this.$moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
                    break;

                default:
                    break;
            }
        },

        viewItem(item) {
            this.dataset.selectedSale = item;
            this.dataset.dialog = true;
            // this.$router.push(`upboxes/${item.id}`);
        },

        viewProof(item) {
            this.dataset.selectedSale = item;
            this.dataset.dialogProof = true;
            // this.$router.push(`upboxes/${item.id}`);
        },

    },
};
</script>

<style>
.flag-icon {
    width: 30px;
}

.custom-modal-title {
    font-size: 20px;

    @media (max-width: 600px) {
        font-size: 14px;
    }
}

.proof-container {
    background-color: #ffffd2 !important;
    padding-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.text-block {
    white-space: pre;
    font-family: "Courier New", serif !important;
    color: #000 !important;
    font-weight: bold;
    margin: 5px 0 0;
    font-size: 10pt;
    text-transform: uppercase;
}

.text-block-mobile {
    white-space: pre;
    font-family: "Courier New", serif !important;
    color: #000 !important;
    font-weight: bold;
    margin: 5px 0 0;
    font-size: 8.5pt;
    text-transform: uppercase;
}
</style>