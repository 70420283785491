const data = [
    { id: "PIX", name: "PIX", icon: 'pix-icon.png' },
    { id: "VISA", name: "Visa", icon: 'visa-icon.png' },
    { id: "MASTER", name: "Mastercard", icon: 'mastercard-icon.png' },
    { id: "ELO", name: "ELO", icon: 'elo-icon.png' },
    { id: "AMEX", name: "AMEX", icon: 'amex-icon.png' },
    { id: "AMERICANEXPRESS", name: "American Express", icon: 'american-express-icon.png' },
    { id: "ALELO", name: "Alelo", icon: 'alelo-icon.png' },
    { id: "HIPERCARD", name: "Hipercard", icon: 'hipercard-icon.png' },
]

export default [...data]